.header{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}
.header .container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 30px;
    max-width: 700px;
    width: 100%;
    align-items: center;
}
.header .main{
    font-family: 'Outfit', Courier, monospace;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    gap: 20px;
    align-items: center;
    width: 100%;
    height: 100%;
}
.header .main .column{
    width: 100%;
    display: flex;
    justify-content: center;
}
.header nav{
    display: flex;
    flex-direction: row;
} 
.header .link{
    font-family: 'Outfit', Courier, monospace;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-right: auto;
    border: none;
    background-color: transparent;
}
.header .logged-in{
    font-family: 'Outfit', Courier, monospace;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 600px;
    font-size: 13.5px;
    color: #C8D8EB; 
}
.header .logged-in .username{
    color: #fff;
}