.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 330px;
    height: auto;
    padding: 50px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #000000;
}
.container h5{
    color: #f5f5f5;
    font-weight: 700;
    text-align: left;
}
.client-login{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.client-login .input{
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 0 15px;
    border-radius: 2px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #373A46;
    background-color: #373A46;
}
.client-login .input::placeholder{
    font-size: 13px;
    color: #d3d0d0;
    font-weight: 500;
}
.client-login .input.error-username,
.client-login .input.error-password{
    border: 1px solid #dd6c6c
}
.client-login button{
    font-family: 'Outfit', Courier, monospace;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #6e6e6e;
    outline: none;
    background-color: transparent;
}
.client-login button:hover{
    color: #000000;
    font-weight: 500;
    border:none;
    background-color: #ffffff;
}
.error{
    font-size: 12px;
    color: #c58484;
    font-weight: 500;
    text-align: center;
}